$(document).ready(function(){

	// Project like, Comment, Star
	$('.projectstext').on('click','.faComment',function(){
		$(this).parents('li.list-inline-item').toggleClass('activeComment');
	})
	$('.projectstext').on('click','.faAchieve',function(){
		$(this).parents('li.list-inline-item').toggleClass('activeAchieve');
	})
	$('.projectstext').on('click','.faLike',function(){
		$(this).parents('li.list-inline-item').toggleClass('activeLike');
	})


 

	// Stricky Header
    $(window).scroll(function(){
        if($(document).scrollTop() > 120){
            $('.arabesqueHeader').addClass('headerStricky');
        }
        else{
            $('.arabesqueHeader').removeClass('headerStricky');
        }
    })

    // Stricky AppIcons
	$(window).scroll(function(){
		if($(document).scrollTop() > 120){
			$('.appIconsTop').addClass('appIconsStricky');
		}
		else{
			$('.appIconsTop').removeClass('appIconsStricky');
		}
	})
/*section for user login start------------*/
$(document).ready(function () {
    $('#loginBtn,#plusLoginBtn,#headerLogin').click(function () {
      $('#loginATab').addClass('active');
      $('#loginA').addClass('active').removeClass('fade');
      $('#registerATab').removeClass('active');
      $('#registerA').removeClass('active').addClass('fade');
      $('.forgotDiv').hide();
      $('.loginForm').show();
    });
    $('#registerBtn,#registerBtnC').click(function () {
      $('#registerATab').addClass('active');
      $('#registerA').addClass('active').removeClass('fade');
      $('#loginATab').removeClass('active');
      $('#loginA').removeClass('active').addClass('fade');
    });
    $('#loginATab').click(function () {
      $('.forgotDiv').hide();
      $('.loginForm').show();
    });
    $('.forgotPass a').click(function () {
      $('.forgotDiv').show();
      $('.loginForm').hide();
    })
    $('.navbar-toggler').click(function () {
      $('.navbar-toggler-icon').toggleClass('CloseIcon');
      $('.menuOverlay').toggleClass('openMenuOver');
    });
    $('.menuOverlay,.arabesqueHeader #navbarsArabes ul li').click(function () {
      $('.navbar-toggler-icon').removeClass('CloseIcon');
      $('.menuOverlay').removeClass('openMenuOver');
      $('#navbarsArabes').removeClass('show');
    });
    $('.loginTimeLineIcon a').click(function () {
      $('.subMenu').toggleClass('ShowTimeLine');
    });
 
  });

/*----------end--------------*/
	// Availability
	// $('.eventDetail').on('click','.confirmAvail li',function(){
	// 	$(this).addClass('availActive').siblings('li').removeClass('availActive');
	// })	

	// Read all
    $('.eventDetail').on('click','.readMore',function(){
        $(this).text('Less...').removeClass('readMore').addClass('lessMore').siblings('.completer').removeClass('d-none');
    })

    $('.eventDetail').on('click','.lessMore',function(){
        $(this).text('Read More...').removeClass('lessMore').addClass('readMore').siblings('.completer').addClass('d-none');
    })    

    // Menu Overlay
    $(document).on('click','.navbar-toggler',function () {
      $('.navbar-toggler-icon').toggleClass('CloseIcon');
      $('.menuOverlay').toggleClass('openMenuOver');
    });

    $(document).on('click','.menuOverlay,.arabesqueHeader #navbarsArabes ul li',function(){
      $('.navbar-toggler-icon').removeClass('CloseIcon');
      $('.menuOverlay').removeClass('openMenuOver');
      $('#navbarsArabes').removeClass('show');
    });

    // Filter
    $(document).on('click','.mobileFilter', function() {    
        $('.pageLeftSidebar').toggleClass('openSide');
        $('.sideBarOverlay').toggleClass('openOver');
    });
    $(document).on('click','.openOver', function() {    
        $('.pageLeftSidebar').removeClass('openSide');
        $(this).removeClass('openOver');
    });

    // Chat
    $(document).on('click','.chatShowButton', function() {    
        $(this).toggleClass('chatShowClose');
        $('.chatlistAbs').toggleClass('chatUserL');
        $('#talkjs-container').removeClass('chatShow');
    });
    $(document).on('click','.onlineuserRow', function() {
        $('#talkjs-container').addClass('chatShow');
    });

    // Remove Event, Group,
    // $(document).on('click','.removeEvent', function() {    
    //     alert('Are you want to sure to remove this event.');
    // });

	// Coin Slider
	// $('.coinSlider').slick({
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	autoplay: true,
	// 	autoplaySpeed: 3000,
	// 	arrows: true,
	// 	dots: true,
	// });

	// Tab Slider
	// $('.profTabMoveS').slick({
	// 	slidesToShow: 5,
	// 	slidesToScroll: 1,
	// 	autoplay: false,
	// 	speed: 300,
	// 	arrows: true,
	// 	dots: false,
	// 	infinite: false,
	// });

	// Check All Recommend
    $(document).on('change','.checkAllRecmm', function() {
        var isChecked = this.checked;    
        if(isChecked) {
            $('.checkRecmm').prop("checked",true);
        } else {
            $('.checkRecmm').prop("checked",false);
        }    
    });

    // Check All Recommend
	$(document).on('change','#isWallPost', function() {
	    var isChecked = this.checked;    
	    if(isChecked) {
	        $('#writeRecommnd5').show();
	    } else {
	        $('#writeRecommnd5').hide();
	    }    
	});

	// Tab Scroll
    var SETTINGS = {
      navBarTravelling: false,
      navBarTravelDirection: "",
      navBarTravelDistance: 150
    }

    var colours = {
        0: "#867100",
        1: "#7F4200",
        2: "#99813D",
        3: "#40FEFF",
        4: "#14CC99",
        5: "#00BAFF",
        6: "#0082B2",
        7: "#B25D7A",
        8: "#00FF17",
        9: "#006B49",
        10: "#00B27A",
        11: "#996B3D",
        12: "#CC7014",
        13: "#40FF8C",
        14: "#FF3400",
        15: "#ECBB5E",
        16: "#ECBB0C",
        17: "#B9D912",
        18: "#253A93",
        19: "#125FB9",
    }

    document.documentElement.classList.remove("no-js");
    document.documentElement.classList.add("js");
    // Out advancer buttons
    var pnAdvancerLeft = document.getElementById("pnAdvancerLeft");
    var pnAdvancerRight = document.getElementById("pnAdvancerRight");
    // the indicator
    var pnIndicator = document.getElementById("pnIndicator");
    var pnProductNav = document.getElementById("pnProductNav");
    var pnProductNavContents = document.getElementById("pnProductNavContents");
    if(pnProductNav!=null && pnProductNavContents!=null){
    
    pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
    // Set the indicator
    moveIndicator(pnProductNav.querySelector("[aria-selected=\"true\"]"), colours[0]);
    
    // Handle the scroll of the horizontal container
    var last_known_scroll_position = 0;
    var ticking = false;
    function doSomething(scroll_pos) {
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
    }

    pnProductNav.addEventListener("scroll", function() {
        last_known_scroll_position = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(function() {
                doSomething(last_known_scroll_position);
                ticking = false;
            });
        }
        ticking = true;
    });
    
    pnAdvancerLeft.addEventListener("click", function() {
        // If in the middle of a move return
        if (SETTINGS.navBarTravelling === true) {
            return;
        }
        // If we have content overflowing both sides or on the left
        if (determineOverflow(pnProductNavContents, pnProductNav) === "left" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
            // Find how far this panel has been scrolled
            var availableScrollLeft = pnProductNav.scrollLeft;
            // If the space available is less than two lots of our desired distance, just move the whole amount
            // otherwise, move by the amount in the settings
            if (availableScrollLeft < SETTINGS.navBarTravelDistance * 2) {
                pnProductNavContents.style.transform = "translateX(" + availableScrollLeft + "px)";
            } else {
                pnProductNavContents.style.transform = "translateX(" + SETTINGS.navBarTravelDistance + "px)";
            }
            // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
            pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
            // Update our settings
            SETTINGS.navBarTravelDirection = "left";
            SETTINGS.navBarTravelling = true;
        }
        // Now update the attribute in the DOM
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
    });
    
    pnAdvancerRight.addEventListener("click", function() {
        // If in the middle of a move return
        if (SETTINGS.navBarTravelling === true) {
            return;
        }
        // If we have content overflowing both sides or on the right
        if (determineOverflow(pnProductNavContents, pnProductNav) === "right" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
            // Get the right edge of the container and content
            var navBarRightEdge = pnProductNavContents.getBoundingClientRect().right;
            var navBarScrollerRightEdge = pnProductNav.getBoundingClientRect().right;
            // Now we know how much space we have available to scroll
            var availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);
            // If the space available is less than two lots of our desired distance, just move the whole amount
            // otherwise, move by the amount in the settings
            if (availableScrollRight < SETTINGS.navBarTravelDistance * 2) {
                pnProductNavContents.style.transform = "translateX(-" + availableScrollRight + "px)";
            } else {
                pnProductNavContents.style.transform = "translateX(-" + SETTINGS.navBarTravelDistance + "px)";
            }
            // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
            pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
            // Update our settings
            SETTINGS.navBarTravelDirection = "right";
            SETTINGS.navBarTravelling = true;
        }
        // Now update the attribute in the DOM
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
    });
    
    pnProductNavContents.addEventListener(
        "transitionend",
        function() {
            // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
            var styleOfTransform = window.getComputedStyle(pnProductNavContents, null);
            var tr = styleOfTransform.getPropertyValue("-webkit-transform") || styleOfTransform.getPropertyValue("transform");
            // If there is no transition we want to default to 0 and not null
            var amount = Math.abs(parseInt(tr.split(",")[4]) || 0);
            pnProductNavContents.style.transform = "none";
            pnProductNavContents.classList.add("pn-ProductNav_Contents-no-transition");
            // Now lets set the scroll position
            if (SETTINGS.navBarTravelDirection === "left") {
                pnProductNav.scrollLeft = pnProductNav.scrollLeft - amount;
            } else {
                pnProductNav.scrollLeft = pnProductNav.scrollLeft + amount;
            }
            SETTINGS.navBarTravelling = false;
        },
        false
    );

    // Handle setting the currently active link
    pnProductNavContents.addEventListener("click", function(e) {
        var links = [].slice.call(document.querySelectorAll(".pn-ProductNav_Link"));
        links.forEach(function(item) {
            item.setAttribute("aria-selected", "false");
        })
        e.target.setAttribute("aria-selected", "true");
        // Pass the clicked item and it's colour to the move indicator function
        moveIndicator(e.target, colours[links.indexOf(e.target)]);
    });
    }
    // var count = 0;
    function moveIndicator(item, color) {
        var textPosition = item.getBoundingClientRect();
        var container = pnProductNavContents.getBoundingClientRect().left;
        var distance = textPosition.left - container;
         var scroll = pnProductNavContents.scrollLeft;
        pnIndicator.style.transform = "translateX(" + (distance + scroll) + "px) scaleX(" + textPosition.width * 0.01 + ")";
        // count = count += 100;
        // pnIndicator.style.transform = "translateX(" + count + "px)";
        
        if (color) {
            pnIndicator.style.backgroundColor = color;
        }
    }

    function determineOverflow(content, container) {
        var containerMetrics = container.getBoundingClientRect();
        var containerMetricsRight = Math.floor(containerMetrics.right);
        var containerMetricsLeft = Math.floor(containerMetrics.left);
        var contentMetrics = content.getBoundingClientRect();
        var contentMetricsRight = Math.floor(contentMetrics.right);
        var contentMetricsLeft = Math.floor(contentMetrics.left);
         if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
            return "both";
        } else if (contentMetricsLeft < containerMetricsLeft) {
            return "left";
        } else if (contentMetricsRight > containerMetricsRight) {
            return "right";
        } else {
            return "none";
        }
    }

    /**
     * @fileoverview dragscroll - scroll area by dragging
     * @version 0.0.8
     * 
     * @license MIT, see http://github.com/asvd/dragscroll
     * @copyright 2015 asvd <heliosframework@gmail.com> 
     */


    (function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            define(['exports'], factory);
        } else if (typeof exports !== 'undefined') {
            factory(exports);
        } else {
            factory((root.dragscroll = {}));
        }
    }(this, function (exports) {
        var _window = window;
        var _document = document;
        var mousemove = 'mousemove';
        var mouseup = 'mouseup';
        var mousedown = 'mousedown';
        var EventListener = 'EventListener';
        var addEventListener = 'add'+EventListener;
        var removeEventListener = 'remove'+EventListener;
        var newScrollX, newScrollY;
        var dragged = [];
        var reset = function(i, el) {
            for (i = 0; i < dragged.length;) {
                el = dragged[i++];
                el = el.container || el;
                el[removeEventListener](mousedown, el.md, 0);
                _window[removeEventListener](mouseup, el.mu, 0);
                _window[removeEventListener](mousemove, el.mm, 0);
            }
            // cloning into array since HTMLCollection is updated dynamically
            dragged = [].slice.call(_document.getElementsByClassName('dragscroll'));
            for (i = 0; i < dragged.length;) {
                (function(el, lastClientX, lastClientY, pushed, scroller, cont){
                    (cont = el.container || el)[addEventListener](
                        mousedown,
                        cont.md = function(e) {
                            if (!el.hasAttribute('nochilddrag') ||
                                _document.elementFromPoint(
                                    e.pageX, e.pageY
                                ) == cont
                            ) {
                                pushed = 1;
                                lastClientX = e.clientX;
                                lastClientY = e.clientY;

                                e.preventDefault();
                            }
                        }, 0
                    );
                    _window[addEventListener](
                        mouseup, cont.mu = function() {pushed = 0;}, 0
                    );
                    _window[addEventListener](
                        mousemove,
                        cont.mm = function(e) {
                            if (pushed) {
                                (scroller = el.scroller||el).scrollLeft -=
                                    newScrollX = (- lastClientX + (lastClientX=e.clientX));
                                scroller.scrollTop -=
                                    newScrollY = (- lastClientY + (lastClientY=e.clientY));
                                if (el == _document.body) {
                                    (scroller = _document.documentElement).scrollLeft -= newScrollX;
                                    scroller.scrollTop -= newScrollY;
                                }
                            }
                        }, 0
                    );
                 })(dragged[i++]);
            }
        }
          
        if (_document.readyState == 'complete') {
            reset();
        } else {
            _window[addEventListener]('load', reset, 0);
        }
        exports.reset = reset;
    }));

	// Date Tie Picker
	// $('.formDatetimeStart,.formDatetimeEnd').datetimepicker({
	//     weekStart: 1,
	//     todayBtn:  1,
	// 	autoclose: 1,
	// 	todayHighlight: 1,
	// 	startView: 2,
	// 	forceParse: 0,
	//     showMeridian: 1,
	//     pickerPosition: 'bottom-left',
	// });

	// File Upload
	// $('.uploadImageRow').on('click', '.uploadImage img', function(){
	// 	$(this).siblings('input').click();
	// });	

	// $("#coverinput").change(function(ev) {
	// 	console.log(ev.target.files[0]);
	// 	file=(ev.target.files[0]);
	// 	fr=new FileReader()
	// 	fr.onload=function(){
	// 		$("#coverimg").attr("src",fr.result)
	// 		console.log(fr)
	// 	}
	// 	if(file)
	// 	{
	// 		return fr.readAsDataURL(file)
	// 	}
	// });

	// $("#profileinput").change(function(ev) {
	// 	console.log(ev.target.files[0]);
	// 	file=(ev.target.files[0]);
	// 	fr=new FileReader()
	// 	fr.onload=function(){
	// 		$("#profileimg").attr("src",fr.result)
	// 		console.log(fr)
	// 	}
	// 	if(file)
	// 	{
	// 		return fr.readAsDataURL(file)
	// 	}
	// });

	// $("#profileinput2").change(function(ev) {
	// 	console.log(ev.target.files[0]);
	// 	file=(ev.target.files[0]);
	// 	fr=new FileReader()
	// 	fr.onload=function(){
	// 		$("#profileimg2").attr("src",fr.result)
	// 		console.log(fr)
	// 	}
	// 	if(file)
	// 	{
	// 		return fr.readAsDataURL(file)
	// 	}
	// });
	
	// Event Steps
	// $('.eventsAllSteps').on('click','.btnNext',function(){
	// 	$(this).parents('.eventStep').hide().next('.eventStep').show();
	// 	$('.fontSsl li').first().addClass('ActiveS');
	// });
	// $('.eventsAllSteps').on('click','.btnPrev',function(){
	// 	$(this).parents('.eventStep').hide().prev('.eventStep').show();
	// });

	// Login/Register
	// $('#loginBtn,#plusLoginBtn').click(function(){
	// 	$('#loginATab').addClass('active');
	// 	$('#loginA').addClass('active').removeClass('fade');
	// 	$('#registerATab').removeClass('active');
	// 	$('#registerA').removeClass('active').addClass('fade');
	// });
	// $('#registerBtn,#registerBtnC').click(function(){
	// 	$('#registerATab').addClass('active');
	// 	$('#registerA').addClass('active').removeClass('fade');
	// 	$('#loginATab').removeClass('active');
	// 	$('#loginA').removeClass('active').addClass('fade');
	// });	
});

// Switcher
function ActivateListView(){
    $('.switchList').addClass('viewActive');
	$('.switchGrid').removeClass('viewActive');
	$('.allProjects').addClass('listViewRow');
	if($('.allProjects > li').hasClass('col-4')){
		$('.allProjects > li').removeClass('col-4').addClass('col-12');
	}
}

function ActivateGridView(){
	$('.switchGrid').addClass('viewActive');
	$('.switchList').removeClass('viewActive');
	$('.allProjects').removeClass('listViewRow');
	if($('.allProjects > li').hasClass('col-12')){
		$('.allProjects > li').removeClass('col-12').addClass('col-4');
	}
}

// Setting Image Value
$(function( $, window, document, undefined ){
$( '.inputhidden' ).each( function(){
	var $input	 = $( this ),
		$label	 = $input.next( 'label' ),
		labelVal = $label.html();
	$input.on( 'change', function( e ){
		var fileName = '';
		if( e.target.value )
			fileName = e.target.value.split( '\\' ).pop();
		if( fileName )
			$label.find( 'span' ).html( fileName );
		else
			$label.html( labelVal );
		});
	});
})

// All Cate Group Sidebar
// $(function(){
// $('.addNew').click(function(){
//  	$(this).toggleClass('addCateMinu');
//  	$('.allCatS').toggle();
// });
// })
// Create product

$(document).on('click','#sellProd', function() {
  	if($('#sellProd').prop('checked')===true) {
     	$('.sellFieldRow').show();
     }
     else {
     	$('.sellFieldRow').hide();
     }
 });

$(document).on('click','#exchaneProd', function() {
  	if($('#exchaneProd').prop('checked')===true) {
     	$('.serviceFieldRow').show();
     }
     else {
     	$('.serviceFieldRow').hide();
     }
 });
$(document).on('click','#barterProd', function() {
  	if($('#barterProd').prop('checked')===true) {
     	$('.barterFieldRow').show();
     }
     else {
     	$('.barterFieldRow').hide();
     }
 });
$(document).on('click','#giftProd', function() {
   	if($('#giftProd').prop('checked')===true) {
     	$('.giftFieldRow').show();
     }
     else {
     	$('.giftFieldRow').hide();
     }
 });

// Read all Wall
$(document).on('click','.readMore',function(){
    $(this).text('Less...').removeClass('readMore').addClass('lessMore').siblings('.completer').removeClass('d-none');
})

$(document).on('click','.lessMore',function(){
    $(this).text('Read More...').removeClass('lessMore').addClass('readMore').siblings('.completer').addClass('d-none');
})

// $('.recommend').click(function(){
// 	$(this).toggleClass('recommendColor');
// });

// $('.timelineCommentList').on('click','.toggleComment',function(){
// 	$(this).toggleClass('pinkComment').parents('li').children('.collapsibleSubComment').toggle();
// });

// $('.inviteBoxWp').on('click','.checkAllPb',function(){
// 	$(this).parents('.inviteBoxWp').children('input').next('.eventStep').show();
// 	$('.fontSsl li').first().addClass('ActiveS');
// });

